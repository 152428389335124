

import React, { useLayoutEffect, useState } from "react";
import classes from "./styles.module.css";
import { Formik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { Link, useSearchParams } from "react-router-dom";
import { IoEyeOutline, IoEyeOffOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import ErrorText from "../../components/errorText";

const validationSchema = Yup.object().shape({
  email: Yup.string().required().email().label("Email"),
  password: Yup.string()
    .min(8, 'Password must be at least 8 characters long')
    .matches(/[A-Z]/, 'Password must contain at least one uppercase character')
    .matches(/[!@#$%^&*(),.?':{}|<>]/, 'Password must contain at least one special character (!, @, #, $, etc.)')
    .required('Password is required'),
  confirmPassword: Yup.string()
    .required()
    .label("Confirm Password")
    .oneOf([Yup.ref('password')], "Passwords must match"),
  referral: Yup.string().label("Referral"),
  ageDeclaration: Yup.boolean().oneOf([true], 'You must declare that you are 18 years and above'),
  terms: Yup.boolean().oneOf([true], 'You must agree to the terms and conditions')
});

export default function Register() {
  const [searchParams] = useSearchParams();
  const referralCode = searchParams.get('code');
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={classes.pageContainer}>
      <Link to='/'>
        <img src="./stecs.svg" alt="logo" className={classes.logo} />
      </Link>
      <div className={classes.wrapper}>
        <header className={classes.header}>
          <h1 className={classes.title}>Accept your invite to Stecs</h1>
          <p className={classes.subtitle}>Sign up for Stecs</p>
        </header>
        <main className={classes.mainContent}>
          <Formik
            initialValues={{
              email: "",
              password: "",
              confirmPassword: "",
              referralCode: referralCode,
              ageDeclaration: false,
              terms: false
            }}
            validateOnMount={true}
            validateOnChange={true}
            validationSchema={validationSchema}
            onSubmit={(values, { setSubmitting }) => {
              setLoading(true);
              axios
                .post("https://strong.stecs.ng/api/sign-up", values)
                .then((response) => {
                  navigate("/congratulations");
                  console.log("Sign-up successful:", response.data);
                  console.log("Sign-up successful:", values);
                })
                .catch((error) => {
                  console.error("Sign-up error:", error);
                })
                .finally(() => {
                  setSubmitting(false);
                  setLoading(false);
                });
            }}
          >
            {({
              handleChange,
              handleSubmit,
              errors,
              setFieldTouched,
              touched,
              values,
              isValid
            }) => (
              <form onSubmit={handleSubmit} className={classes.form}>
                <div className={classes.formField}>
                  <label htmlFor="email">Email</label>
                  <input
                    id="email"
                    onChange={handleChange("email")}
                    type="email"
                    name="email"
                    onBlur={() => setFieldTouched("email")}
                    placeholder="Enter your email"
                    value={values.email}
                  />
                  {touched.email && <ErrorText message={errors.email} />}
                </div>

                <div className={classes.formField}>
                  <label htmlFor="password">Password</label>
                  <div className={classes.passwordContainer}>
                    <input
                      id="password"
                      onChange={handleChange("password")}
                      type={showPassword ? "text" : "password"}
                      name="password"
                      onBlur={() => setFieldTouched("password")}
                      placeholder="Enter your password"
                      className={classes.passwordInput}
                      value={values.password}
                    />
                    <div
                      onClick={() => setShowPassword(!showPassword)}
                      className={classes.eyeContainer}
                    >
                      {showPassword ? <IoEyeOutline /> : <IoEyeOffOutline />}
                    </div>
                  </div>
                  {touched.password && <ErrorText message={errors.password} />}
                </div>

                <div className={classes.formField}>
                  <label htmlFor="confirmPassword">Confirm Password</label>
                  <div className={classes.passwordContainer}>
                    <input
                      id="confirmPassword"
                      onChange={handleChange("confirmPassword")}
                      type={showPassword ? "text" : "password"}
                      name="confirmPassword"
                      onBlur={() => setFieldTouched("confirmPassword")}
                      placeholder="Confirm your password"
                      className={classes.passwordInput}
                      value={values.confirmPassword}
                    />
                    <div
                      onClick={() => setShowPassword(!showPassword)}
                      className={classes.eyeContainer}
                    >
                      {showPassword ? <IoEyeOutline /> : <IoEyeOffOutline />}
                    </div>
                  </div>
                  {touched.confirmPassword && <ErrorText message={errors.confirmPassword} />}
                </div>

                <div className={classes.formField}>
                  <label htmlFor="referral">Referral Code</label>
                  <input
                    id="referral"
                    onChange={handleChange("referral")}
                    type="text"
                    readOnly
                    name="referral"
                    onBlur={() => setFieldTouched("referral")}
                    placeholder="Enter your referral code"
                    value={values.referralCode}
                  />
                </div>

                <div className={classes.checkboxGroup}>
                  <div className={classes.checkboxField}>
                    <div className={classes.checkboxWrapper}>
                      <input
                        id="ageDeclaration"
                        type="checkbox"
                        onChange={handleChange("ageDeclaration")}
                        onBlur={() => setFieldTouched("ageDeclaration")}
                        checked={values.ageDeclaration}
                      />
                      <label htmlFor="ageDeclaration">I am 18 years and above</label>
                    </div>
                    {touched.ageDeclaration && <ErrorText message={errors.ageDeclaration} />}
                  </div>
                  <div className={classes.checkboxField}>
                    <div className={classes.checkboxWrapper}>
                      <input
                        id="terms"
                        type="checkbox"
                        onChange={handleChange("terms")}
                        onBlur={() => setFieldTouched("terms")}
                        checked={values.terms}
                      />
                      <label htmlFor="terms">
                        By clicking the sign-up button, you have read and agree to the
                        <Link to='/terms' target="_blank"> Terms and Conditions </Link>
                        and
                        <Link to='/policy' target="_blank"> Privacy Policy </Link>
                        for Stecs.
                      </label>
                    </div>
                    {touched.terms && <ErrorText message={errors.terms} />}
                  </div>
                </div>

                <button
                  className={classes.submitButton}
                  type="submit"
                  disabled={loading || !isValid}
                >
                  {loading ? <div className={classes.loader}></div> : "Sign Up"}
                </button>
              </form>
            )}
          </Formik>
        </main>
      </div>
    </div>
  );
}
