import React from 'react';
import styles from './styles.module.css';
// import { Link } from 'react-router-dom';

const Policy = () => {
    // const handleBackButtonClick = () => {
    //     window.history.back();
    // };

    return (
        <div className={styles.policyContainer}>
            <header className={styles.header}>
                <h1>Privacy Policy</h1>
                {/* <button
                    className={styles.backButton}
                    onClick={handleBackButtonClick}
                    aria-label="Go back to the previous page"
                > */}
                {/* <Link to='/register' aria-label="Go back to the registration page">
                    Back
                </Link> */}
                {/* </button> */}
            </header>
            <div className={styles.content}>
                <p className={styles.date}>Current as of 20 Jan 2022</p>

                <section className={styles.policySection}>
                    <h2>Privacy Policy</h2>
                    <p>
                        This Privacy Policy (the “Policy”) applies to Stecs Financial Technologies Limited’s
                        online interface (i.e., website or mobile application) and any affiliate, subsidiary
                        or online interface that is linked to this Policy, (individually referred to as a
                        “site”, and, collectively, “sites”). The term (“the company” or "Stecs”, “we", "us",
                        "our") in this Policy shall refer to STECS Financial Technologies Ltd and any of its
                        affiliate, subsidiary that is linked to this policy. Stecs is committed to protecting
                        the privacy of its users ("you", "your"). This Privacy Policy explains how we collect,
                        use, and protect personal information that we collect from you when you use our
                        website or services. By using our website or services, you agree to the terms of this
                        Privacy Policy.
                    </p>
                    <p>
                        Please read this Policy carefully. By using the Site and any services we offer via the
                        Site, you are agreeing to be bound by this Policy with respect to the information
                        collected about you via this Site.
                    </p>
                    <p>
                        You may use the Site only for lawful purposes. You may not use the Site: In any way that
                        breaches any applicable local, national or international law or regulation. In any way
                        that is unlawful or fraudulent or has any unlawful or fraudulent purpose or effect. For
                        the purpose of harming or attempting to harm any person and in any way. To bully,
                        insult, intimidate or humiliate any person. To send, knowingly receive, upload,
                        download, use or re-use any material which does not comply with our content standards.
                        To transmit, or procure the sending of, any unsolicited or unauthorised advertising or
                        promotional material or any other form of similar solicitation (spam). To knowingly
                        transmit any data, send or upload any material that contains viruses, trojan horses,
                        worms, time-bombs, keystroke loggers, spyware, adware or any other programs or similar
                        computer code designed to adversely affect the operation of any computer software or
                        hardware.
                    </p>
                    <p>
                        You also agree:
                    </p>
                    <p>
                        Not to reproduce, duplicate, copy or re-sell any part of our Site as this is in
                        contravention of the provisions of our terms of use. Not to access without authority,
                        interfere with, damage or disrupt: any part of our Site; any equipment or network on
                        which our Site is stored; or any software used in the provision of our Site
                    </p>
                </section>
                <section>
                    <h3>2. Types of Personal Information We Collect</h3>
                    <p>
                        We collect personal information from you when you use our site.
                    </p>
                    <p>
                        We may collect the following information from you: name, username, gender, date of
                        birth; email address, postal address, telephone number; your interests; account
                        number, card number and expiry date or any other information that may be required
                    </p>
                </section>
                <section>
                    <h3>3. How We Use Your Personal Information</h3>
                    <p>
                        We use your personal information to provide our services to you, to communicate with
                        you, to process payments, and to improve our services. Specifically, we may use your
                        personal information for the following purposes: To create and maintain your account
                        To process payments and provide you with our services To communicate with you
                        regarding our services, updates, and promotions To respond to your inquiries and
                        provide customer support To improve and personalize our services To comply with legal
                        obligations and regulatory requirements.
                    </p>
                </section>
                <section>
                    <h3>4. How We Share Your Personal Information</h3>
                    <p>
                        Services available on this Site are offered in conjunction with some of our business
                        partners, including Providus Bank Limited (the "business partners"). In order for
                        services to be provided, we may share some necessary details about you with the
                        Business Partners, and according to our business dynamics and the continued provision
                        of efficient services to you, we may, from time to time, transfer such details to
                        other Business Partners as we deem fit. However, under any circumstance, we will take
                        steps to ensure that your privacy rights continue to be protected. We may transfer
                        your personal information to any other third party as part of a sale of some or all of
                        our business and assets to any third party or as part of any business restructuring or
                        reorganisation, but we will take steps with the aim of ensuring that your privacy
                        rights continue to be protected. In addition, we may pass your information onto one of
                        our carefully selected business partners or to other carefully selected third parties
                        to enable them to send you information which may be of interest to you but only if you
                        have given us permission to do so. You can stop receiving such mail whenever you
                        choose by unsubscribing via the link provided. We may also share your personal
                        information with regulatory authorities or law enforcement agencies as required by law
                        or to protect our legal interests. Other than as set out above, we will not disclose
                        any of your personal information without your permission.
                    </p>
                </section>
                <section>
                    <h3>5. Security of Your Personal Information</h3>
                    <p>
                        We take appropriate technical and organizational measures to protect your personal
                        information against unauthorized access, disclosure, alteration, or destruction. We
                        implement measures such as encryption, firewalls, and access controls to protect your
                        personal information. We use secure server software (SSL) to encrypt any financial
                        information you need to input before it is sent to us. We also require our service
                        providers to implement appropriate security measures.
                    </p>
                </section>
                <section>
                    <h3>6. Retention of Your Personal Information</h3>
                    <p>
                        We retain your personal information for as long as necessary to provide our services
                        to you, to comply with legal obligations, or to protect our legal interests. We will
                        delete your personal information when it is no longer necessary for these purposes.
                    </p>
                </section>
                <section>
                    <h3>7. Your Rights</h3>
                    <p>
                        You have the right to access, correct, or delete your personal information. You may
                        also object to the processing of your personal information or request a restriction on
                        its processing. You may exercise these rights by contacting us using the contact
                        details provided below.
                    </p>
                </section>
                <section>
                    <h3>8. Cookies and Tracking Technologies</h3>
                    <p>
                        We use cookies and other tracking technologies to collect information about your use
                        of our site. This information may include your IP address, browser type, operating
                        system, and other usage information. We use this information to analyze trends,
                        administer our website, and personalize your experience. You may disable cookies in
                        your browser settings, but this may affect the functionality of our website and
                        services. A number of cookies we use last only for the duration of your session and
                        expire when you close your browser. Other cookies are used to remember you when you
                        return to the site and will last for longer. A cookie helps you get the best out of
                        the Site and helps us to provide you with a more customised service. We use cookies:
                        so that you will not have to re-enter your details each time you visit the Site to
                        track how our Site is used and to improve and update our content store your
                        preferences customise elements of the layout and/or content of the site for you
                        collect statistical information about how you use the site so that we can improve the
                        site You can refuse to accept these cookies and most devices and browsers offer their
                        own privacy settings for cookies. You will need to manage your cookie settings for
                        each device and browser you use. However, if you do not accept these cookies, you may
                        experience some inconvenience in your use of the Site and some online products and
                        services. For example, we will not be able to recognize your device and you will need
                        to answer a challenge question each time you log on. You also may not receive tailored
                        advertising or other offers from us that may be relevant to your interests and needs.
                    </p>
                </section>
                <section>
                    <h3>9. Changes to your Details</h3>
                    <p>
                        Keeping your account information accurate and up to date is very important. If your
                        account information is incomplete, inaccurate or not current, please use the Contact
                        Us option on our Site, or call or write to us at the telephone numbers or appropriate
                        address for changes listed on your account statements, records, online or other
                        account materials. You can also speak to a customer representative from the Company.
                    </p>
                </section>
                <section>
                    <h3>10. Updates to this Privacy Policy</h3>
                    <p>
                        We may update this Privacy Policy from time to time. We will notify you of any
                        material changes to this Privacy Policy by posting a notice on our website or by other
                        means.
                    </p>
                </section>
                <section>
                    <h3>11. Contact Us</h3>
                    <p>
                        If you have any questions or concerns about this Privacy Policy or our data protection
                        practices, please contact us by sending an email to hello@stecs.ng or writing
                        to us at 12F, Tola Adewumi Street, Maryland Estate, Maryland, Lagos.
                    </p>
                </section>
            </div>
        </div>
    );
};

export default Policy;
